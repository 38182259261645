import myserver from './myHttp'
export const getUpgradeList = (params) => {
  return myserver.myHttp({
    url: '/sysFunction/purchaseInfo',
    params
  })
}
export const genLevelPayOrder = (data) => {
  return myserver.myHttp({
    url: '/sysPayOrder/genLevelPayOrder',
    method: 'POST',
    data
  })
}