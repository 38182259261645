import axios from 'axios';
import { ElMessage } from 'element-plus';
import router from '@/router';
let baseURL;
if(process.env.NODE_ENV === 'development'){
  baseURL = '/api';
}else{
  baseURL = process.env.VUE_APP_BASE_URL;
}
let config = {
  baseURL,
  timeout: 60 * 1000
};
let is401 = false;
const _axios = axios.create(config);
// _axios.interceptors.request.use(config=>{
//   return config
// })
_axios.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  if (error&&error.response) {
    if(error.response.data&&error.response.data.message){
      ElMessage({
        showClose: true,
        message: error.response.data.message,
        type: 'error'
      });
    }else{
      if(error.response.status===401){
        if(!is401){
          is401 = true;
          sessionStorage.clear()
          router.push({
            path:'/login'
          });
        }else{
          return
        }
      }else{
        ElMessage({
          showClose: true,
          message: '服务器遇到点问题',
          type: 'error'
        });
      }
    }
  }
  return Promise.reject(error);
});
export default _axios