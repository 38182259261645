import myserver from './myHttp'
export const getUserInfo = (params) => {
  return myserver.myHttp({
    url: '/sysUser',
    params
  })
}
export const updateTel = (data) => {
 return myserver.myHttp({
   url: '/sysUser/updateTel',
   method: 'POST',
   data
 }) 
}
export const updatePsd = (data) => {
  return myserver.myHttp({
    url: '/sysUser/changePwd',
    method: 'POST',
    data
  })
}
export const save = (data) => {
  return myserver.myHttp({
    url: '/sysUser/saveUser',
    method: 'POST',
    data
  })
}
export const bindWx = (data) => {
  return myserver.myHttp({
    url: '/wx/changeBind',
    method: 'POST',
    data
  })
}
export const getCode = (data) => {
  return myserver.myHttp({
    url: '/openApi/sendVerifyCode',
    method: 'POST',
    data
  })
}
export const getListByDictType = (params) => {
  return myserver.myHttp({
    url: '/sysDict/getListByDictType',
    params
  })
}
export const uploadFile = (data) => {
  return myserver.myHttp({
    url: '/tFile/uploadAttachment',
    method: 'POST',
    data
  })
}