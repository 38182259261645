const getToDay = ()=>{
  const date = new Date();
  const year = date.getFullYear();
  const mon = date.getMonth() + 1;
  const day = date.getDate();
  return {
    year,
    mon,
    day
  }
};
// 当天年月日
const getTodayByFull = () => {
  const {year,mon,day} = getToDay();
  return `${year}年${mon}月${day}日`;
};
// 当天月和日
const getTodayByDay = ()=>{
  const {mon,day} = getToDay();
  return `${mon}月${day}日`;
};
// 判断是否为当天
const isToday = ()=>{
  return getTodayByFull() === ''
};
//日期转换
const transformDate = (propsDays)=>{
  if(propsDays.includes('年')&&propsDays.includes('月')&&propsDays.includes('日')){
    let selectDate = propsDays.replace(/年|月|日/g,'-').substring(0,propsDays.length-1).split('-');
    let mon = selectDate[1]<10?`0${selectDate[1]}`:selectDate[1];
    let day = selectDate[2]<10?`0${selectDate[2]}`:selectDate[2];
    propsDays = `${selectDate[0]}-${mon}-${day}`;
  };
  return propsDays
}
export{
  getTodayByFull,
  getTodayByDay,
  isToday,
  getToDay,
  transformDate
}