<template>
  <div :class='["myAppLvs",loginOrRegist?"loginOrRegist":""]'>
    <div class="appTop" v-if='hideTop'>
      <div class="container">
        <div class="topBar">
          <router-link to='/home/homeMain'><img src="@/assets/image/logo.png" alt="律梯" /></router-link>
          <div class="topMenu">
            <div class='topSerchInput'>
              <el-input placeholder="请输入要搜索的内容" v-model="searchState.topSerchInput" clearable
                @keyup.enter="openSearchDialog">
              </el-input>
              <div class="iconfont iconfangdajing mainColor" @click="openSearchDialog"></div>
            </div>
            <div class="message">
              <div @click='showMessage' class="iconfont iconlaba mainColor">
                <div class="dian"></div>
              </div>
            </div>
            <div class="userSetting">
              <el-popover placement="bottom" popper-class='homePop' v-model:visible='userMenuBtn' @show='showPop'
                @hide='showPop'>
                <template #reference>
                  <div class="iconfont iconyonghu mainColor"></div>
                </template>
                <div class="settingBox">
                  <div class="popTop">
                    <div class="userName" v-if='userName'>{{userName}}</div>
                    <div class="userTel" v-if='userTel'>{{userTel}}</div>
                  </div>
                  <div class="popList">
                    <div class="tab">
                      <router-link to='/account'>账号设置</router-link>
                    </div>
                    <div class="tab" v-for='item in userMenu' :key='item.id'>
                      <router-link :to='item.path'>{{item.name}}</router-link>
                    </div>
                    <div class="tab">
                      <p @click="handleClickUpgrade">续费/升级</p>
                    </div>
                    <div class="tab out">
                      <router-link to='/quit'>退出</router-link>
                    </div>
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class='["appCenter",loginOrRegist?"loginOrRegist":""]'>
      <div class="topNav" v-if='hideTop'>
        <div class="container">
          <div class="todyMess">
            <div class="title">{{timeMsg}}</div>
            <div class="soup">{{chickenSoup}}</div>
            <div class="date">{{currentDate + ' ' + festivalName}}</div>
          </div>
          <div class="topNavList">
            <div class="navLink">
              <router-link to='/home/homeMain' :class='pathName.includes("/home")?"activet":""'>
                <div class="iconfont iconwode"></div>
                <div class="iconText">我的</div>
                <div class="bar"></div>
              </router-link>
            </div>
            <div class="navLink" v-for='item in userNavList' :key='item.navIcon'>
              <router-link :to='item.path' :class='pathName.includes(item.path)?"activet":""'>
                <div class="iconfont" :class='item.navIcon'>
                  <div class="dian" v-if='item.navName==="审批"'></div>
                </div>
                <div class="iconText">{{item.navName}}</div>
                <div class="bar"></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <div class="appFooter" v-if='hideFooter' :style="marginTop?'margin-top:0':''">
      <div class="container">
        <span>南京共律科技  苏 ICP 备 19008991 号-1</span>
        <span>苏公网安备 32011502011173 号</span>
      </div>
    </div>
    <!-- 弹框 -->
    <myDialog :isShow='isShow' :closeDialog='closeDialog'>
      <div class="myMessageBox">
        <div class="messageLeft">
          <div class="navBox">
            <div class="navList">
              <div :class='["navItem",currentIdx===index?"on":""]' v-for='(item,index) in messageNavList' :key='item.id'
                @click='messageNavClick(index)'>
                <div :class="['iconfont',item.icon]"></div>
                {{item.title}}
              </div>
            </div>
            <div class="navBar">
              <div class="bar" ref='navBar'></div>
            </div>
          </div>
          <div class="leftBot">
            <div class="codeImg">
              <img src="@/assets/image/code.svg" />
            </div>
            <div class="codeText">
              <div style='margin-bottom:8px'>关注公众号</div>
              <div>更方便的接收消息</div>
            </div>
          </div>
        </div>
        <div class="messageRight">
          <div class="messageList">
            <div class="messageItem" v-for='item in 1' :key='item'>
              <div class="title">您提交的用印审批已经通过</div>
              <div class="time">2021-02-12 12:30</div>
            </div>
          </div>
          <div class="paginationBox">
            <el-pagination small 
            @size-change="handleSizeChange" 
            @current-change="handleCurrentChange"
              :current-page="currentPage" 
              :page-sizes="PAGE_SIZES" 
              :page-size="PAGE_SIZE"
              layout="total,sizes,prev,pager,next" :total="120">
            </el-pagination>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 续费/升级弹框 -->
    <myDialog :isShow="showUpgradeDialog" :title="dialogTitle" :closeDialog="closeUpgradeDialog">
      <div class="upgrade_dialog" v-loading="loading">
        <!-- 续费/升级内容 -->
        <div class="upgrade_wrap" v-show="showUpgrade">
          <div class="upgrade_item" v-for="(item, index) in state.productList" :key="index">
            <div class="top">
              <div class="top_icon iconfont iconlifangtilitiduomiantifangkuai"></div>
              <div class="title">{{item.productName}}</div>
              <div class="purchase" @click="handlePurchase" v-if="item.needPurchase">购买</div>
            </div>
            <div class="tab_wrap" v-if="!item.needPurchase">
              <div class="tab_item" :class="{active: item.active == index}" v-for="(tabItem, index) in item.tabArr"
                :key="index" @click="handleClickTab(item,index)">{{tabItem + '(12项)'}}</div>
            </div>
            <!-- 标配 -->
            <div class="list_wrap" v-if="(!item.needPurchase) && item.active == 0">
              <div class="list_item" v-for="(standardItem, index) in item.stdFunctionList" :key="index">
                <div class="major_wrap" @click="handleClickMajor(standardItem)">
                  <div class="icon">{{standardItem.expand ? '-' : '+'}}</div>
                  <div class="con">{{standardItem.functionName}}</div>
                  <div class="detail">{{standardItem.expand ? '收起' : '展开'}}</div>
                </div>
                <!-- 标配子级 -->
                <div v-if="standardItem.expand">
                  <div class="sub_wrap" v-for="(childrenItem, index) in standardItem.children" :key="index">
                    <div class="icon">-</div>
                    <div class="con">{{childrenItem.functionName}}</div>
                    <div class="detail">详情</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="select_wrap" v-if="(!item.needPurchase) && item.active == 1">
              <div class="select_item" v-for="(selectItem, index) in item.nonStdFunctionList" :key="index">
                <div class="icon iconduihao iconfont" :class="{acitve_icon: selectItem.effective}"></div>
                <div class="title">{{selectItem.functionName}}</div>
                <div class="detail">详情</div>
                <!-- !selectItem.effective -->
                <div class="sub_select_wrap" v-if="!selectItem.effective">
                  <div class="purchase open" @click="handleOpen('功能选配',selectItem)">开通</div>
                </div>
                <div class="sub_select_wrap" v-else>
                  <div class="sub_select_item" v-if="selectItem.endDate">
                    <div class="date">{{selectItem.endDate + '到期' }}</div>
                    <div class="purchase" @click="handleRePay">续费</div>
                  </div>
                  <div class="sub_select_item" v-if="selectItem.canUpgrade == 1">
                    <div class="date">{{selectItem.effectiveLevel}}</div>
                    <div class="purchase" @click="handleOpen('升级',selectItem)">升级</div>
                  </div>
                  <div class="sub_select_item" v-if="(!selectItem.endDate) && selectItem.effective">
                    <div class="date">永久可用</div>
                    <div class="purchase forbid">续费</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- 功能选配的内容 -->
        <div class="func_select_wrap" v-show="!showUpgrade">
          <div class="list_wrap">
            <div class="top">
              <div class="card_icon iconfont icondianzimingpian"></div>
              <div class="title">{{funcName}}</div>
            </div>
            <div class="list_item" v-for="(item, index) in state.selectiveLevelList" :key="index">
              <div class="con">{{item.levelName}}</div>
              <div class="detail">详情</div>
              <div class="price">
                {{item.price || item.price == 0 ? item.price + '元/' + item.validDateUnitName : item.validDateUnitName}}
              </div>
              <div class="purchase" :class="{upgrade: !item.innerEffective && dialogTitle == '升级'}"
                @click="handleOpenUpGrade(item)">{{item.innerEffective ? '当前使用' : dialogTitle == '升级' ? '去升级' : '开通'}}
              </div>
            </div>
          </div>
        </div>
        <div class="back iconfont iconlfArrow" v-show="!showUpgrade" @click="showUpgrade = true"></div>
      </div>
    </myDialog>
    <myDialog :isShow="showDialog" :title="dialogTitle2" :closeDialog="closeUpgradeDialog">
      <div class="pay_dialog">
        <div class="pay_item">
          <div class="title">购买方案:</div>
          <div class="con">{{state.payForm.price + '元/' + state.payForm.priceCycleName}}</div>
        </div>
        <div class="pay_item">
          <div class="title">购买时长:</div>
          <div class="con">
            <el-input-number v-if='!purchaseForever' v-model="state.payForm.quantity" @change="handleChangeNum" :min="1" size="large">
            </el-input-number> <span class="price_cycle">{{state.payForm.priceCycleName}}</span>
          </div>
        </div>
        <div class="pay_item">
          <div class="title">购买后:</div>
          <div class="con">有效期至:{{purchaseForever?'永久':state.payForm.endDate}}</div>
        </div>
        <div class="pay_item">
          <div class="title">支付方式:</div>
          <div class="con">
            <el-radio v-model="state.payForm.radio" label="1" border>微信支付</el-radio>
          </div>
        </div>
        <div class="btn_wrap">
          <div class="total_price">
            总价: ￥<span>{{purchaseForever?purchasePrice:state.payForm.totalPrice}}</span>
          </div>
          <el-button type="primary" @click="handlePay">支付</el-button>
        </div>
      </div>
    </myDialog>
    <!-- 二维码弹框 -->
    <myDialog :isShow="showCodeDialog" title="支付" :closeDialog="closeUpgradeDialog">
      <div class="code_dialog">
        <!-- <vue-qr :logo-src="logoSrc" :size="300" :margin="0" :auto-color="true" :dot-scale="1" :text="state.appSrc" /> -->
        <div class="qrcode" id="qrcode"></div>
      </div>
    </myDialog>
    <!-- 全局搜索弹框 -->
    <myDialog :isShow="showSearchDialog" :closeDialog="handleCloseSearchDialog">
      <div class="search_dialog">
        <div class="search_wrap">
          <el-input class="ipt" placeholder="请输入检索内容" prefix-icon="iconfont iconfangdajing"
            v-model="searchState.queryParams.keyword" @input="handleSearch">
          </el-input>
        </div>
        <div class="tab_wrap">
          <div class="tab_item" :class="{active: searchState.idx == index}" v-for="(item, index) in searchState.tabArr"
            :key="index" @click="handleClickSearchTabItem(index)">{{item}}</div>
        </div>
        <div style="max-height: 70vh;overflow:auto">
          <div class="content_wrap">
            <div class="content_item"
              v-show="(searchState.idx == 0 || searchState.idx == 1) && searchState.todoPage.length > 0">
              <div class="content_top">
                <div class="title"><span class="iconfont iconshenpitongguo"></span>待办</div>
                <div class="more" v-show="searchState.idx == 0" @click="lookMore(1)">查看更多</div>
              </div>
              <div class="content_main">
                <div class="list_item" v-for="(item, index) in searchState.todoPage" :key="index">
                  <div class="status">{{item.isFinish==0? '未完成' : '已完成'}}</div>
                  <div class="title">{{item.todoName}}</div>
                  <div class="time">{{item.createDate}}</div>
                  <div class="detail">详情</div>
                </div>
              </div>
              <div class="pagination_wrap" v-show="searchState.idx == 1">
                <el-pagination @size-change="handleSearchSizeChange" @current-change="handleSearchCurrentChange"
                  layout="total, sizes, prev, pager, next, jumper" :total="searchState.todoPageTotal">
                </el-pagination>
              </div>
            </div>
            <div class="content_item"
              v-show="(searchState.idx == 0 || searchState.idx == 2) && searchState.casePage.length > 0">
              <div class="content_top">
                <div class="title"><span class="iconfont iconanjiantiaocha"></span>案件</div>
                <div class="more" v-show="searchState.idx == 0" @click="lookMore(2)">查看更多</div>
              </div>
              <div class="content_main">
                <div class="list_item" v-for="(item, index) in searchState.casePage" :key="index">
                  <div class="status">已完成</div>
                  <div class="title">{{item.caseName}}</div>
                  <div class="time">{{item.createDate}}</div>
                  <div class="detail">详情</div>
                </div>
              </div>
              <div class="pagination_wrap" v-show="searchState.idx == 2">
                <el-pagination @size-change="handleSearchSizeChange" @current-change="handleSearchCurrentChange"
                  layout="total, sizes, prev, pager, next, jumper" :total="searchState.casePageTotal">
                </el-pagination>
              </div>
            </div>
            <div class="content_item"
              v-show="(searchState.idx == 0 || searchState.idx == 3) && searchState.projectPage.length > 0">
              <div class="content_top">
                <div class="title"><span class="iconfont iconxiangmu"></span>项目</div>
                <div class="more" v-show="searchState.idx == 0" @click="lookMore(3)">查看更多</div>
              </div>
              <div class="content_main">
                <div class="list_item" v-for="(item, index) in searchState.projectPage" :key="index">
                  <div class="status">已完成</div>
                  <div class="title">{{item.projectName}}</div>
                  <div class="time">{{item.createDate}}</div>
                  <div class="detail">详情</div>
                </div>
              </div>
              <div class="pagination_wrap" v-show="searchState.idx == 3">
                <el-pagination @size-change="handleSearchSizeChange" @current-change="handleSearchCurrentChange"
                  layout="total, sizes, prev, pager, next, jumper" :total="searchState.projectPageTotal">
                </el-pagination>
              </div>
            </div>
            <div class="content_item"
              v-show="(searchState.idx == 0 || searchState.idx == 4) && searchState.customerPage.length > 0">
              <div class="content_top">
                <div class="title"><span class="iconfont iconkehuguanli"></span>客户</div>
                <div class="more" v-show="searchState.idx == 0" @click="lookMore(4)">查看更多</div>
              </div>
              <div class="content_main">
                <div class="list_item" v-for="(item, index) in searchState.customerPage" :key="index">
                  <div class="status">已完成</div>
                  <div class="title">{{item.customerName}}</div>
                  <div class="time">{{item.createDate}}</div>
                  <div class="detail">详情</div>
                </div>
              </div>
              <div class="pagination_wrap" v-show="searchState.idx == 4">
                <el-pagination @size-change="handleSearchSizeChange" @current-change="handleSearchCurrentChange"
                  layout="total, sizes, prev, pager, next, jumper" :total="searchState.customerPageTotal">
                </el-pagination>
              </div>
            </div>
            <div class="content_item"
              v-show="(searchState.idx == 0 || searchState.idx == 5) && searchState.applyPage.length > 0">
              <div class="content_top">
                <div class="title"><span class="iconfont iconshenpiguanli"></span>审批</div>
                <div class="more" v-show="searchState.idx == 0" @click="lookMore(5)">查看更多</div>
              </div>
              <div class="content_main">
                <div class="list_item" v-for="(item, index) in searchState.applyPage" :key="index">
                  <div class="status">{{item.statusName}}</div>
                  <div class="title">{{item.applyName}}</div>
                  <div class="time">{{item.createDate}}</div>
                  <div class="detail">详情</div>
                </div>
              </div>
              <div class="pagination_wrap" v-show="searchState.idx == 5">
                <el-pagination @size-change="handleSearchSizeChange" @current-change="handleSearchCurrentChange"
                  layout="total, sizes, prev, pager, next, jumper" :total="searchState.applyPageTotal">
                </el-pagination>
              </div>
            </div>
          </div>
          <div class="no_data"
            v-show="(searchState.idx === 0 && searchState.todoPage.length == 0 && searchState.casePage.length == 0 && searchState.projectPage.length == 0 && searchState.customerPage.length == 0 && searchState.applyPage.length == 0) || (searchState.idx === 1 && searchState.todoPage.length == 0) || (searchState.idx === 2 && searchState.casePage.length == 0) || (searchState.idx === 3 && searchState.projectPage.length == 0) || (searchState.idx === 4 && searchState.customerPage.length == 0) || (searchState.idx === 5 && searchState.applyPage.length == 0)">
            <i class="el-icon-warning-outline"></i>
            <span>暂无数据</span>
            </div>
        </div>
      </div>
    </myDialog>
    
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    reactive,
    ref,
    watch
  } from 'vue';
  import QRCode from 'qrcodejs2'
  import {
    useRoute,
    useRouter
  } from 'vue-router';
  import {
    useStore
  } from 'vuex';
  import myDialog from './components/myDialog.vue';
  import {
    useConst
  } from '@/hooks/usePagination.js';
  import {
    getUpgradeList,
    genLevelPayOrder
  } from '@/api/upgrade'
  import {
    globalSearch,
    searchToDo,
    searchCustomer,
    searchCase,
    searchProject,
    searchApply
  } from '@/api/search'
  import {
    getDate,
    getPrePayOrder,
    wxPayCB
  } from '@/api/price'
  import {
    getGreeting
  } from '@/api'
  import {
    getUserInfo
  } from '@/api/account.js';
  import {
    ElMessage
  } from 'element-plus';
  const useIsHide = () => {
    let hideTopArr = ["Login", "Register", "ResetPwd", "404", "Lvteam", "Price", "BindWx"];
    let hideFooterArr = ["Login", "Register", "ResetPwd", "404", "BindWx"];
    let hideTop = ref(false);
    let hideFooter = ref(false);
    let loginOrRegist = ref(false);
    let marginTop = ref(false);
    const currentRoute = useRoute();
    watch(() => currentRoute.path, () => {
      if(currentRoute.path.includes('/lvteam')){
        marginTop.value = true
      }else{
        marginTop.value = false
      };
      if (hideFooterArr.includes(currentRoute.name)) {
        hideFooter.value = false
      } else {
        hideFooter.value = true
      };
      if (hideTopArr.includes(currentRoute.name)) {
        hideTop.value = false;
        loginOrRegist.value = true
      } else {
        hideTop.value = true;
        loginOrRegist.value = false
      };
    })
    return {
      hideFooter,
      hideTop,
      loginOrRegist,
      marginTop
    }
  }
  // 用户个人设置的菜单
  const useUserMenu = () => {
    let userMenuBtn = ref(false);
    const route = useRoute();
    const store = useStore();
    let pathName = ref('');
    let chickenSoup = ref('')
    let festivalName = ref('')
    let timeMsg = ref('')
    let currentDate = ref('')
    const greeting = async () => {
      let res = await getGreeting()
      if (res.code == 200) {
        timeMsg.value = res.data.timeMsg
        festivalName.value = res.data.festivalName ? res.data.festivalName : ''
        chickenSoup.value = res.data.chickenSoup
      }
    }

    const getCurrentDate = () => {
      var myDate = new Date();
      var year = myDate.getFullYear(); //年
      var month = myDate.getMonth() + 1; //月
      var day = myDate.getDate(); //日
      var days = myDate.getDay();
      switch (days) {
        case 1:
          days = '星期一';
          break;
        case 2:
          days = '星期二';
          break;
        case 3:
          days = '星期三';
          break;
        case 4:
          days = '星期四';
          break;
        case 5:
          days = '星期五';
          break;
        case 6:
          days = '星期六';
          break;
        case 0:
          days = '星期日';
          break;
      }
      var str = year + "年" + month + "月" + day + "日  " + days;
      currentDate.value = str
    }
    // 用户权限菜单
    onMounted(async () => {
      if(sessionStorage.getItem('token')){
        store.dispatch('getRoutes', sessionStorage.getItem('token'));
        getCurrentDate();
        // 获取用户信息
        if(sessionStorage.getItem('token')){
          if(!store.state.userName||!store.state.userTel){
          let userInfoRes = await getUserInfo();
          if(userInfoRes&&userInfoRes.code==200){
            store.commit('getUserInfo',{
              userName:userInfoRes.data.userName,
              userTel:userInfoRes.data.tel
            });
          }
        }
        }
      }
    })
    const userNavList = computed(() => {
      return store.getters.getUserNavList
    });
    // 个人中心设置
    let userMenu = reactive([{
        id: 1,
        name: "新建律所",
        path: "/"
      },
      {
        id: 2,
        name: "我的律所",
        path: "/"
      },
      {
        id: 3,
        name: "律所管理",
        path: "/"
      }
    ])
    watch(() => route.path, (newV) => {
      if (newV === '/home/homeMain') {
        greeting()
      }
      userMenuBtn.value = false;
      pathName.value = newV;
    });
    return {
      userMenu,
      userMenuBtn,
      userNavList,
      pathName,
      greeting,
      chickenSoup,
      festivalName,
      timeMsg,
      currentDate
    }
  };
  // 消息弹框
  const useShowMessage = () => {
    let isShow = ref(false);
    const showMessage = () => {
      isShow.value = true
    };
    // 关闭弹框
    const closeDialog = () => {
      isShow.value = false;
      console.log('closeDialog')
    };
    return {
      showMessage,
      isShow,
      closeDialog
    }
  }
  // 我的信息
  const useMyMessage = () => {
    const messageNavList = reactive([{
      id: 1,
      title: '全部信息',
      icon: 'iconxiaoxi'
    }, {
      id: 2,
      title: '审批信息',
      icon: 'iconshenpiguanli'
    }, {
      id: 3,
      title: '日程信息',
      icon: 'iconricheng'
    }, {
      id: 4,
      title: '系统信息',
      icon: 'iconxitong'
    }]);
    const navBar = ref(null);
    let currentIdx = ref(0);
    const messageNavClick = async (idx) => {
      currentIdx.value = idx;
      let topPx = computed(() => {
        return idx * 70 + 'px'
      });
      await nextTick();
      navBar.value.style.top = topPx.value;
    }
    return {
      messageNavList,
      messageNavClick,
      navBar,
      currentIdx
    }
  }
  // 分页
  const useCurrentPagination = () => {
    let currentPage = ref(1);
    const SizeChange = (val) => {
      console.log(`每页 ${val} 条`);
    };
    const CurrentChange = (val) => {
      console.log(`当前页: ${val}`);
    };
    return {
      SizeChange,
      CurrentChange,
      currentPage
    }
  }
  // 续费升级
  const useUpgrade = () => {
    let innerEffective = ref(false)
    let showDialog = ref(false)
    let showCodeDialog = ref(false)
    let timer = ref(null)
    let dialogTitle2 = ref('')
    let funcName = ref('')
    const loading = ref(false)
    const levelId = ref(0)
    let relType = ref(0)
    const showUpgrade = ref(true) // 控制续费/升级弹框的内容的显示与隐藏
    const handleRePay = () => {
      showDialog.value = true
      showUpgradeDialog.value = false
      relType.value = 4
    }
    const state = reactive({
      productList: [],
      selectiveLevelList: [],
      payForm: {
        radio: '1',
        quantity: 1,
        totalPrice: 0,
        endDate: '',
        priceCycle: null,
        priceCycleName: null
      }
    })
    let dialogTitle = ref('续费/升级')
    const defaultProps = ref({
      children: 'children',
      label: 'label'
    })
    const showUpgradeDialog = ref(false)
    const router = useRouter()
    const handlePay = async () => {
      let params = {
        levelId: levelId.value,
        quantity: state.payForm.quantity,
        relType: relType.value
      }

      let res = await genLevelPayOrder(params)
      if (res.code == 200) {
        let params2 = {
          openId: '',
          payOrderId: res.data.payOrderId,
          wxPayType: 'NATIVE'
        }
        let res2 = await getPrePayOrder(params2)
        console.log(res2);
        if (res2.code == 200) {
          showCodeDialog.value = true
          state.appSrc = res2.data.codeUrl
          // console.log(object);
          // console.log(state.appSrc);
          // new QRCode('',{
          //   text: res2.data.codeUrl,
          //   width: 300,
          //   height: 300,
          //   correctLevel: QRCode.CorrectLevel.H
          // })
          await nextTick()
          let qrcode = new QRCode(document.querySelector('#qrcode'), res2.data.codeUrl)
          timer.value = setInterval(async () => {
            let res3 = await wxPayCB({
              payOrderId: res.data.payOrderId
            })
            if (res3.data.status == 2||(!showCodeDialog.value||!showDialog.value)) {
              showCodeDialog.value = false
              showDialog.value = false
              state.appSrc = '';
              qrcode.clear();
              clearInterval(timer.value)
            }
          }, 2000);
        }
      }

    }
    const getList = async () => {
      loading.value = true
      let res = await getUpgradeList()
      loading.value = false
      if (res && res.code == 200) {
        res.data.productList.map((item) => {
          item.tabArr = ['标配功能', '选配功能']
          item.active = 0
          item.stdFunctionList.map((item2) => {
            item2.expand = false
          })
          item.nonStdFunctionList.map((item3) => {
            item3.expand = false
          })
        })
        state.productList = res.data.productList
      }
    }
    const handleChangeNum = async (val) => {
      let params = {
        numbers: val,
        functionLevelId: levelId.value
      }
      let res = await getDate(params)
      state.payForm.endDate = res.data
      state.payForm.totalPrice = val * state.payForm.price
    }
    let purchasePrice = ref('');
    let purchaseForever = ref(false);
    const handleOpenUpGrade = (item) => {
      if (item.innerEffective) {
        return
      };
      state.payForm.quantity = 1;
      state.payForm.totalPrice= 0
      state.payForm.endDate=''
      state.payForm.priceCycle=null
      state.payForm.priceCycleName=null
      state.payForm.radio='1'
      showDialog.value = true
      showUpgradeDialog.value = false
      dialogTitle2.value = item.levelName
      state.payForm.price = item.price
      state.payForm.priceCycle = item.validDateUnit
      state.payForm.priceCycleName = item.validDateUnitName
      levelId.value = item.levelId
      innerEffective.value = item.innerEffective
      if(item.validDateUnitName==='永久'){
        purchasePrice.value = state.payForm.price;
        purchaseForever.value = true;
        return
      }else{
        purchasePrice.value='';
        purchaseForever.value=false;
        handleChangeNum(1)
      }
    }
    const handleOpen = (title, item) => {
      console.log('title:',title)
      console.log('item:',item)
      dialogTitle.value = title
      funcName.value = item.functionName
      if (title === '功能选配') {
        relType.value = 2
      } else {
        relType.value = 5
      }
      showUpgrade.value = false
      state.selectiveLevelList = item.selectiveLevelList
    }
    const handleClickTab = (item, index) => {
      item.active = index
    }
    const closeUpgradeDialog = () => {
      showUpgradeDialog.value = false
      showDialog.value = false
      showCodeDialog.value = false
    }
    const handlePurchase = () => {
      showUpgradeDialog.value = false
      router.push({
        path: '/lvteam/price'
      })
    }
    const handleClickMajor = (item) => {
      item.expand = !item.expand
    }
    const handleNodeClick = (params) => {

    }
    return {
      purchasePrice,
      purchaseForever,
      handleClickTab,
      showUpgradeDialog,
      closeUpgradeDialog,
      handlePurchase,
      handleClickMajor,
      dialogTitle,
      defaultProps,
      handleNodeClick,
      getList,
      showUpgrade,
      state,
      handleOpen,
      loading,
      showDialog,
      dialogTitle2,
      handleOpenUpGrade,
      handleChangeNum,
      handlePay,
      levelId,
      relType,
      handleRePay,
      timer,
      showCodeDialog,
      funcName
    }
  }
  // 全局搜索
  const useGlobalSearch = () => {
    const showSearchDialog = ref(false)
    const searchState = reactive({
      tabArr: ['全部', '待办', '案件', '项目', '客户', '审批'],
      idx: 0,
      topSerchInput: '',
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        keyword: ''
      },
      applyPage: [],
      casePage: [],
      customerPage: [],
      projectPage: [],
      todoPage: [],
      applyPageTotal: 0,
      casePageTotal: 0,
      customerPageTotal: 0,
      projectPageTotal: 0,
      todoPageTotal: 0
    })
    const openSearchDialog = async () => {
      if (searchState.topSerchInput == '') {
        ElMessage.warning("请输入要搜索的内容")
        return
      }
      showSearchDialog.value = true
      searchState.queryParams.keyword = ''
      searchState.idx = 0
      let res = await globalSearch({
        keyword: searchState.topSerchInput
      })
      if (res.code == 200) {
        searchState.topSerchInput = ''
        searchState.applyPageTotal = parseInt(res.data.applyPage.total)
        searchState.casePageTotal = parseInt(res.data.casePage.total)
        searchState.customerPageTotal = parseInt(res.data.customerPage.total)
        searchState.projectPageTotal = parseInt(res.data.projectPage.total)
        searchState.todoPageTotal = parseInt(res.data.todoPage.total)
        if (searchState.idx === 0) {
          searchState.casePage = res.data.casePage.records.length > 3 ? res.data.casePage.records.slice(0, 3) :
            res.data.casePage.records
          searchState.applyPage = res.data.applyPage.records.length > 3 ? res.data.applyPage.records.slice(0,
            3) : res.data.applyPage.records
          searchState.customerPage = res.data.customerPage.records.length > 3 ? res.data.customerPage.records
            .slice(0, 3) : res.data.customerPage.records
          searchState.projectPage = res.data.projectPage.records.length > 3 ?
            res.data.projectPage.records.slice(0, 3) : res.data.projectPage.records
          searchState.todoPage = res.data.todoPage.records.length > 3 ?
            res.data.todoPage.records.slice(0, 3) : res.data.todoPage.records
        }
      }
    }
    const handleCloseSearchDialog = () => {
      showSearchDialog.value = false
    }
    const handleClickSearchTabItem = (index) => {
      searchState.idx = index
    }
    const handleSearch = async () => {
      let res
      switch (searchState.idx) {
        case 0:
          res = await globalSearch({
            keyword: searchState.queryParams.keyword
          })
          if (res.code == 200) {
            searchState.applyPageTotal = parseInt(res.data.applyPage.total)
            searchState.casePageTotal = parseInt(res.data.casePage.total)
            searchState.customerPageTotal = parseInt(res.data.customerPage.total)
            searchState.projectPageTotal = parseInt(res.data.projectPage.total)
            searchState.todoPageTotal = parseInt(res.data.todoPage.total)
            searchState.casePage = res.data.casePage.records.length > 3 ? res.data.casePage.records.slice(0, 3) :
              res.data.casePage.records
            searchState.applyPage = res.data.applyPage.records.length > 3 ? res.data.applyPage.records.slice(0,
              3) : res.data.applyPage.records
            searchState.customerPage = res.data.customerPage.records.length > 3 ? res.data.customerPage.records
              .slice(0, 3) : res.data.customerPage.records
            searchState.projectPage = res.data.projectPage.records.length > 3 ?
              res.data.projectPage.records.slice(0, 3) : res.data.projectPage.records
            searchState.todoPage = res.data.todoPage.records.length > 3 ?
              res.data.todoPage.records.slice(0, 3) : res.data.todoPage.records
          }
          break;
        case 1:
          res = await searchToDo(searchState.queryParams)
          if (res.code == 200) {
            searchState.todoPage = res.data.records
            searchState.todoPageTotal = parseInt(res.data.total)
          }
          break;
        case 2:
          res = await searchCase(searchState.queryParams)
          if (res.code == 200) {
            searchState.casePage = res.data.records
            searchState.casePageTotal = parseInt(res.data.total)
          }
          break;
        case 3:
          res = await searchProject(searchState.queryParams)
          if (res.code == 200) {
            searchState.projectPage = res.data.records
            searchState.projectPageTotal = parseInt(res.data.total)
          }
          break;
        case 4:
          res = await searchCustomer(searchState.queryParams)
          if (res.code == 200) {
            searchState.customerPage = res.data.records
            searchState.customerPageTotal = parseInt(res.data.total)
          }
          break;
        case 5:
          res = await searchApply(searchState.queryParams)
          if (res.code == 200) {
            searchState.applyPage = res.data.records
            searchState.applyPageTotal = parseInt(res.data.total)
          }
          break;

        default:
          break;
      }
    }
    const lookMore = (index) => {
      searchState.idx = index
    }
    const handleSearchSizeChange = (val) => {
      searchState.queryParams.pageSize = val
      handleSearch()
    }
    const handleSearchCurrentChange = (val) => {
      searchState.queryParams.pageNo = val
      handleSearch()
    }
    return {
      openSearchDialog,
      showSearchDialog,
      handleCloseSearchDialog,
      searchState,
      handleClickSearchTabItem,
      handleSearch,
      lookMore,
      handleSearchSizeChange,
      handleSearchCurrentChange
    }
  }

  export default defineComponent({
    name: 'App',
    setup() {
      const {
        hideFooter,
        hideTop,
        loginOrRegist,
        marginTop
      } = useIsHide();
      const {
        userNavList,
        userMenu,
        userMenuBtn,
        pathName,
        greeting,
        chickenSoup,
        festivalName,
        timeMsg,
        currentDate
      } = useUserMenu();
      const {
        showMessage,
        isShow,
        closeDialog
      } = useShowMessage();
      const {
        messageNavList,
        messageNavClick,
        navBar,
        currentIdx
      } = useMyMessage();
      const {
        SizeChange,
        CurrentChange,
        currentPage
      } = useCurrentPagination();
      const {
        PAGE_SIZE,
        PAGE_SIZES,
        handleSizeChange,
        handleCurrentChange
      } = useConst(SizeChange, CurrentChange);
      const handleClickUpgrade = () => {
        showUpgradeDialog.value = true
        showUpgrade.value = true
        userMenuBtn.value = false
        getList()
      }
      const {
        handleClickTab,
        showUpgradeDialog,
        closeUpgradeDialog,
        handlePurchase,
        handleClickMajor,
        dialogTitle,
        defaultProps,
        handleNodeClick,
        getList,
        showUpgrade,
        state,
        handleOpen,
        loading,
        showDialog,
        dialogTitle2,
        handleOpenUpGrade,
        handleChangeNum,
        handlePay,
        levelId,
        relType,
        handleRePay,
        timer,
        showCodeDialog,
        funcName,
        purchasePrice,
      purchaseForever,
      } = useUpgrade()
      const {
        openSearchDialog,
        showSearchDialog,
        handleCloseSearchDialog,
        searchState,
        handleClickSearchTabItem,
        handleSearch,
        lookMore,
        handleSearchSizeChange,
        handleSearchCurrentChange
      } = useGlobalSearch()
      return {
        hideFooter,
        hideTop,
        loginOrRegist,
        userNavList,
        userMenu,
        userMenuBtn,
        pathName,
        showMessage,
        isShow,
        closeDialog,
        messageNavList,
        messageNavClick,
        navBar,
        currentIdx,
        PAGE_SIZE,
        PAGE_SIZES,
        handleSizeChange,
        handleCurrentChange,
        currentPage,
        handleClickTab,
        showUpgradeDialog,
        closeUpgradeDialog,
        handlePurchase,
        handleClickMajor,
        handleClickUpgrade,
        dialogTitle,
        defaultProps,
        handleNodeClick,
        getList,
        showUpgrade,
        state,
        handleOpen,
        loading,
        showDialog,
        dialogTitle2,
        handleOpenUpGrade,
        handleChangeNum,
        handlePay,
        levelId,
        relType,
        handleRePay,
        timer,
        showCodeDialog,
        funcName,
        openSearchDialog,
        showSearchDialog,
        handleCloseSearchDialog,
        searchState,
        handleClickSearchTabItem,
        handleSearch,
        lookMore,
        greeting,
        chickenSoup,
        festivalName,
        timeMsg,
        currentDate,
        handleSearchSizeChange,
        handleSearchCurrentChange,
        purchasePrice,
      purchaseForever,
      marginTop
      }
    },
    components: {
      myDialog
    },
    data() {
      return {
        homePop: null,
      }
    },
    methods: {
      async showPop() {
        this.$nextTick(() => {
          if (this.homePop) {
            this.homePop.style.minWidth = '125px';
            this.homePop.style.width = '125px';
          }
        })
      },

    },
    computed:{
      userName(){
        return this.$store.state.userName
      },
      userTel(){
        return this.$store.state.userTel
      }
    },
    async mounted() {
      this.homePop = document.getElementsByClassName('homePop')[0];
    }
  })
</script>
<style lang="scss" scoped>
  @import '@/assets/style/comman.scss';

  .mainColor {
    color: $main-color
  }

  .iconfangdajing,
  .iconlaba {
    font-size: 22px;

    &:hover {
      cursor: pointer;
    }
  }

  .iconyonghu {
    font-size: 25px;

    &:hover {
      cursor: pointer;
    }
  }

  .myAppLvs {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .appTop {
      width: 100%;
      background: #fff;

      .container {

        .topBar,
        .topNav {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .topBar {
          background: #fff;
          height: 100px;

          .topMenu {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .topSerchInput {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 300px;
              height: 40px;
              border-bottom: 1px solid #ddd;

              .el-input {
                width: 270px;

                ::v-deep {
                  .el-input__inner {
                    border: none
                  }
                }
              }
            }

            .message {
              margin: 0 25px;

              .iconlaba {
                position: relative;

                .dian {
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
                  background: #FF494E;
                  position: absolute;
                  right: -6px;
                  top: -6px;
                }
              }
            }

            .userSetting {
              .iconyonghu {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .topNav {
      padding: 40px 0 50px 0;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .todyMess {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 50px;

          .title,
          .soup {
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            color: #555;
          }

          .date {
            font-size: 14px;
            color: #777
          }
        }

        .topNavList {
          display: flex;
          align-items: center;

          .navLink {
            margin-left: 45px;
            padding: 0 2px;

            a {
              text-decoration: none;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              height: 50px;
              position: relative;

              .bar {
                display: none;
              }

              .iconText {
                font-size: 14px;
                color: #777;
              }

              .iconfont {
                color: #777;
                font-size: 26px;
                position: relative;

                .dian {
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
                  background: #FF494E;
                  position: absolute;
                  right: -6px;
                  top: -6px;
                }
              }

              .iconwode,
              .iconanjiantiaocha,
              .iconshenpitongguo,
              .iconkehuguanli {
                font-size: 28px;
              }

              &:hover {
                color: $main-color;

                .iconText,
                .iconfont {
                  color: $main-color;
                }
              }
            }

            .activet {
              color: $main-color;

              .bar {
                @include underLine;
                width: 40px;
                display: block;
              }

              .iconText {
                color: $main-color;
              }

              .iconfont {
                color: $main-color;
              }
            }

          }

        }
      }
    }

    .appCenter {
      min-height: calc(100vh - 180px);
      // padding-bottom:30px
    }

    .appFooter {
      width: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      margin-top:20px;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          font-size: 14px;
          color:#666;
          margin:0 10px
        }
      }
    }
  }

  .settingBox {
    padding: 0 10px;

    .popTop {
      border-bottom: 1px solid #ddd;
      margin-bottom: 15px;
      padding-bottom: 15px;

      .userName {
        font-size: 14px;
        color: #555;
        padding-top: 10px;
        margin-bottom: 10px
      }

      .userTel {
        font-size: 12px;
        color: #999;
      }
    }

    .popList {
      .tab {
        font-size: 14px;
        margin-bottom: 15px;
        cursor: pointer;

        a,
        p {
          color: #666;
          text-decoration: none;

          &:hover {
            color: $main-color
          }
        }
      }

      .out {
        margin-top: 20px;

        a {
          color: #e4393c;
        }
      }
    }
  }

  .myMessageBox {
    width: 800px;
    // height: 640px;
    max-height:70vh;
    display: flex;
    justify-content: space-between;

    .messageLeft {
      width: 220px;
      box-sizing: border-box;
      padding: 60px 30px 70px 30px;
      border-right: 1px solid #eee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .leftBot {
        .codeImg {
          width: 96px;
          height: 96px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0px 0px 20px rgba(220, 223, 230, 1);

          img {
            width: 92px;
            height: 92px;
          }
        }

        .codeText {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #777;
          margin-top: 20px
        }
      }

      .navBox {
        width: 100%;
        height: 100%;
        position: relative;

        .navList {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .navItem {
            width: 88px;
            height: 50px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: #555;

            &:hover {
              cursor: pointer;
              color: $main-color;

              .iconfont {
                color: $main-color;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            .iconfont {
              font-size: 22px;
              color: #555;
            }

            .iconxiaoxi {
              font-size: 19px;
            }
          }

          .on {
            color: $main-color;

            .iconfont {
              color: $main-color;
            }
          }
        }

        .navBar {
          position: absolute;
          right: 0;
          top: 0;
          width: 3px;
          height: 100%;

          .bar {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 50px;
            background: $main-color;
            transition: top .3s;
          }
        }
      }
    }

    .messageRight {
      display: flex;
      flex-direction:column;
      justify-content: space-between;
      width: 580px;
      box-sizing: border-box;
      padding: 0 0 30px 30px;

      .messageList {
        width: 100%;
        padding-right: 30px;
        box-sizing: border-box;
        margin-bottom: 30px;
        // height:100%;
        // overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 6px;
          height: 10px
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #999
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: #ddd;
        }

        .messageItem {
          height: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          border-bottom: 1px solid #eee;

          .title {
            font-size: 14px;
            color: #555;
            margin-bottom: 6px;
          }

          .time {
            font-size: 12px;
            color: #999;
          }

          &:hover {
            cursor: pointer;

            .title {
              color: $main-color;
            }
          }

          &:last-child {
            border-bottom: none
          }
        }
      }

      .paginationBox {
        width: 100%;
        text-align: center;
      }
    }
  }

  // .loginOrRegist {
  //   height: 100%
  // }

  .upgrade_dialog {
    width: 580px;
    // min-height: 200px;
    margin: 0 auto;

    .back {
      position: absolute;
      top: 22px;
      left: 10px;
      cursor: pointer;
    }
  }

  .upgrade_wrap {
    padding: 10px;

    .upgrade_item {
      padding: 20px;
      border: 1px solid #ffe7a1;
      border-radius: 8px;
      margin: 10px 0;
      max-height: 380px;
      overflow: auto;

      .top {
        display: flex;
        height: 32px;
        align-items: center;
        font-size: 14px;

        .top_icon {
          font-size: 25px;
          color: $main-color;
        }

        .title {
          color: #797979;
          margin-left: 20px;
          flex: 1;
        }

        // .date {
        //   color: #333;
        //   font-size: 12px;
        //   margin-right: 10px;
        // }

        .purchase {
          color: #ff494e;
          cursor: pointer;
        }
      }

      .tab_wrap {
        height: 33px;
        display: flex;
        margin-top: 10px;

        .tab_item {
          height: 100%;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #dcdfe6;
          color: #333;
          font-size: 12px;
          cursor: pointer;

          &.active {
            background: #ffdbb3;
            color: $main-color;
          }
        }
      }

      .list_wrap {
        .custom-tree-node {
          flex: 1;
          display: flex;

          .label_title {
            flex: 1;
          }

          .detail_text {
            color: $main-color;
          }
        }

        .list_item {

          .major_wrap,
          .sub_wrap {
            display: flex;
            height: 40px;
            align-items: center;
            font-size: 14px;
            color: #333;
            margin-left: 20px;
            cursor: pointer;

            .con {
              flex: 1;
              margin-left: 10px;
              @include ellipsis;
            }
          }

          .major_wrap {
            border-bottom: 1px solid #dcdfe6;
          }

          .sub_wrap {
            .icon {
              margin-left: 25px;
            }

            .size {
              color: #606266;
            }

            .upgrade_text {
              color: #ff494e;
              margin: 0 10px;
            }

            .detail {
              color: $main-color;
            }
          }

          &:last-child {
            .major_wrap {
              border-bottom: none;
            }
          }
        }
      }

      .select_wrap {
        .select_item {
          min-height: 40px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333;
          border-bottom: 1px solid #dcdfe6;

          .icon {
            width: 18px;
            height: 13px;
            color: #797979;
            text-align: center;

            &.acitve_icon {
              color: $main-color;
            }
          }

          .title {
            margin-left: 10px;
            width: 120px;
          }

          .detail {
            color: $main-color;
            margin: 0 70px;
          }

          .sub_select_wrap {
            flex: 1;

            .open {
              color: #02dd90;
              text-align: right;
              cursor: pointer;
            }

            .sub_select_item {
              display: flex;
              justify-content: space-between;
              height: 30px;
              align-items: center;

              .date {
                font-size: 12px;
                color: #797979;
              }

              .purchase {
                color: #ff494e;
                cursor: pointer;
              }

              .forbid {
                color: #dcdfe6;
              }
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .func_select_wrap {
    padding: 10px;

    .list_wrap {
      padding: 20px;
      border: 1px solid #ffe7a1;
      border-radius: 8px;
      margin: 10px 0;
      overflow: auto;

      .top {
        display: flex;
        height: 32px;
        align-items: center;
        font-size: 14px;
        border-bottom: 1px solid #dcdfe6;

        .card_icon {
          font-size: 35px;
          color: $main-color;
          margin-right: 10px;
        }
      }

      .list_item {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;
        padding: 0 10px;
        border-bottom: 1px solid #dcdfe6;

        .detail {
          color: #FF8600;
          margin: 0 70px;
        }

        .con {
          width: 140px;
        }

        .price {
          color: #606266;
          flex: 1;
        }

        .purchase {
          color: #02dd90;
          cursor: pointer;

          &.upgrade {
            color: #ff494e;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .pay_dialog {
    padding: 10px;

    .pay_item {
      height: 40px;
      display: flex;
      align-items: center;

      .title {
        width: 120px;
        text-align: left;
      }

      .con {
        .price_cycle {
          margin-left: 10px;
        }
      }
    }

    .btn_wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #333;
      padding: 0 20px;

      span {
        font-size: 30px;
        font-weight: 900;
        margin: 0 10px;
      }
    }
  }

  .code_dialog {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 300px;
      height: 300px;
    }
  }

  .search_dialog {
    padding: 10px;
    width: 800px;

    .search_wrap {
      width: 650px;
      position: absolute;
      top: 10px;

      .ipt {

        ::v-deep .el-input__inner {
          border-radius: 20px;
        }
      }
    }

    .tab_wrap {
      display: flex;

      .tab_item {
        margin: 0 10px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid $main-color;
          color: $main-color;
        }

        &:first-child {
          margin: 0 10px 0 0;
        }
      }
    }

    .content_wrap {
      margin-top: 10px;
      width: 780px;
      margin: 0 auto;

      .content_item {
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 35%);
        margin: 10px 0;

        .content_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #e1e1e1;
          height: 40px;
          padding: 0 10px;

          .title {
            display: flex;
            align-items: center;

            .iconfont {
              color: $main-color;
              font-size: 24px;
              margin-right: 10px;
            }
          }

          .more {
            color: $main-color;
            cursor: pointer;
          }
        }

        .content_main {
          padding: 0 30px;

          .list_item {
            height: 56px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333;

            .status {
              width: 130px;
            }

            .title {
              width: 400px;
              color: #797979;
            }

            .time {
              font-size: 12px;
              width: 160px;
            }

            .detail {
              font-size: 12px;
            }

          }
        }

        .pagination_wrap {
          display: flex;
          height: 60px;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }

    .no_data {
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .el-icon-warning-outline{
        color: $main-color;
        font-size: 32px;
        margin-bottom: 20px;
      }
    }
  }
</style>