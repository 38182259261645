import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/element-variables.scss'
import './assets/style/global.scss'
// elementUI
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale'
import lang from 'element-plus/lib/locale/lang/zh-cn'
locale.use(lang)
import './assets/style/reset.css'
const app = createApp(App);
// UI框架
app.config.globalProperties.$ELEMENT = { size: 'middle' }
import { 
  ElButton,
  ElCheckbox,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElRadio,
  ElSelect,
  ElSwitch,
  ElTable,
  ElTabPane,
  ElTableColumn,
  ElTabs,
  ElTag,
  ElTimePicker,
  ElTimeSelect,
  ElTree,
  ElUpload,
  ElTooltip,
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElStep,
  ElSteps,
  ElCalendar,
  ElPopover,
  ElPagination,
  ElAutocomplete,
  ElOption,
  ElDatePicker,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElRadioGroup,
  ElCascader,
  ElInputNumber,
  ElCheckboxGroup,
  ElAlert,
  ElImage
 } from 'element-plus';
const components = [
  ElImage,
  ElAlert,
  ElButton,
  ElCheckbox,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElRadio,
  ElSelect,
  ElSwitch,
  ElTable,
  ElTabPane,
  ElTableColumn,
  ElTabs,
  ElTag,
  ElTimePicker,
  ElTimeSelect,
  ElTree,
  ElUpload,
  ElTooltip,
  ElStep,
  ElSteps,
  ElCalendar,
  ElPopover,
  ElPagination,
  ElAutocomplete,
  ElOption,
  ElDatePicker,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElRadioGroup,
  ElCascader,
  ElInputNumber,
  ElCheckboxGroup
];
components.forEach(component => {
  app.component(component.name, component)
});
const plugins = [
  ElLoading,
  ElMessage,
  ElMessageBox
];
plugins.forEach(plugin => {
  app.use(plugin)
});

// echarts
import * as echarts from 'echarts/core';
import {
  PieChart,
  BarChart
} from 'echarts/charts';
import {
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  VisualMapComponent,
  GridComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
echarts.use(
  [TitleComponent, TooltipComponent, VisualMapComponent, LegendComponent, PieChart, CanvasRenderer,GridComponent,BarChart]
);
app.config.globalProperties.$echarts = echarts;
// 异常
app.config.errorHandler = (err, vm, info) => {
  console.log('Vue捕获到异常err：',err)
}
app.use(store).use(router).mount('#app')