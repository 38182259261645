import { createStore } from 'vuex';
import {getTodayByFull,transformDate} from '@/utils/today.js';
import router from '../router';
import {routeComponent,routesFilter} from '../utils/transformConponents.js';
import {getListByDictType} from '@/api/index.js';
// 测试代码
import moocRoutes from '@/utils/mooc.json';
export default createStore({
  state: {
    // 当天日期
    tody:getTodayByFull(),
    // 用户点击的日期-年月日格式
    homeCalendar:getTodayByFull(),
    // 用户当月日期待办数据
    calendarData:[],
    // 用户全部路由
    userRoutesList:[],
    // 数据字典
    dataDictionary:new Map(),
    userName:'',
    userTel:'',
    getCurrentbeLoingsId:''
  },
  mutations: {
    // 获取用户信息
    getUserInfo(state,val){
      state.userName = val.userName;
      state.userTel = val.userTel
    },
    // 用户点击日历
    changeDate(state,val){
      // 年月日格式
      state.homeCalendar = val;
    },
    // 动态更新路由表
    updataRoutes(state,props){
      let roleRoutes = props;
      roleRoutes = routesFilter(roleRoutes);
      roleRoutes.forEach(item=>{
        router.addRoute(item);
      })
    },
    getCurrentbeLoingsId(state,val){
      state.getCurrentbeLoingsId = val
    },
  },
  actions: {
    // 获取用户路由权限
    getRoutes(store,props){
      // token不存在不执行
      if(!props){
        console.log('token不存在不执行');
        return
      };
      if(sessionStorage.getItem('userNavList')){
        let sessionRoutes = sessionStorage.getItem('userNavList');
        sessionRoutes = JSON.parse(sessionRoutes);
        store.state.userRoutesList = sessionRoutes;
        // store.commit('updataRoutes',sessionRoutes);
      }else{
        // 模拟路由数据
        let p = Promise.resolve();
        p.then(()=>{
          let roleRoutes = moocRoutes;
          sessionStorage.setItem('userNavList',JSON.stringify(roleRoutes));
          store.state.userRoutesList = roleRoutes;
          store.commit('updataRoutes',roleRoutes);
        })
      };
    },
    // 获取数据字典
    async getDataDictionary(store,id){
      if(id){
        let map = store.state.dataDictionary;
        if(map.has(id)){
          return map.get(id)
        }else{
          let res = await getListByDictType({id});
          if(res&&res.code==200){
            if(map.size>10){
              const keysArr = [];
              for(let key of Object.keys(map)){
                keysArr.push(key)
              }
              map.delete(keysArr[0])
            };
            map.set(id,res.data);
            return res.data
          }
        }
      }else{
        return '字典类型Id不能为空'
      }
    },
    // 获取字典id对应的名称
    async getDictionaryName(store,props){
      const {id,status} = props;
      if(id&&status){
        const map = store.state.dataDictionary;
        let dataArr;
        if(map.has(id)){
          dataArr = map.get(id);
        }else{
          dataArr = await store.dispatch('getDataDictionary',id);
        };
        dataArr = dataArr.filter(item=>{
          return item.key === status
        });
        if(dataArr.length){
          return dataArr[0].value
        };
        return '字典类型不存在'
      }else{
        return ''
      }
    }
  },
  getters:{
    isTody(state){
      return state.homeCalendar === state.tody
    },
    // 获取路由中的导航
    getUserNavList(state){
      return state.userRoutesList.filter(item=>{
        return item.navName
      });
    },
    
  }
})
