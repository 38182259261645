import {ref,reactive} from 'vue';
import { PAGE_SIZE as pageSize, PAGE_SIZES as pageSizes } from '@/utils/const.js';
const useConst = (SizeChange,CurrentChange)=>{
  const PAGE_SIZE = ref(pageSize);
  const PAGE_SIZES = reactive(pageSizes);
  const handleSizeChange = (val)=>{
    PAGE_SIZE.value = val;
    if(SizeChange){
      SizeChange(val)
    }
  };
  const handleCurrentChange = (val)=>{
    if(CurrentChange){
      CurrentChange(val)
    }
  };
  return {
    PAGE_SIZE,
    PAGE_SIZES,
    handleSizeChange,
    handleCurrentChange
  }
}
export {
  useConst
}