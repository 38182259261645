import { createRouter, createWebHashHistory } from 'vue-router';
import {routeComponent,routesFilter} from '../utils/transformConponents.js';
let routes = [
  {
    path: '/',
    name: 'Main',
    redirect:'/home/homeMain'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    beforeEnter(to,from,next){
      sessionStorage.clear();
      next()
    }
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import(/* webpackChunkName: "resetPwd" */ '../views/ResetPwd.vue')
  },
  {
    path: '/home',
    name: 'Home',
    redirect:'/home/homeMain',
    meta:{
      requireLogin:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    children:[
      {
        path: '/home/homeData',
        name:'HomeData',
        meta:{
          requireLogin:true
        },
        component: () => import(/* webpackChunkName: "homeData" */ '../views/HomeData.vue')
      },
      {
        path: '/home/homeMain',
        name:'HomeMain',
        meta:{
          requireLogin:true
        },
        component: () => import(/* webpackChunkName: "homeMain" */ '../views/HomeMain.vue')
      }
    ]
  },
  {
    path: '/conflict',
    name:'Conflict',
    meta:{
      requireLogin:true
    },
    component: () => import(/* webpackChunkName: "homeMain" */ '../views/Conflict.vue')
  },
  {
    path: '/quit',
    name: 'Quit',
    component: () => import(/* webpackChunkName: "quit" */ '../views/Quit.vue'),
    beforeEnter(to,from,next){
      sessionStorage.clear();
      next('/login')
    }
  },
  {
    path: '/lvteam',
    name: 'Lvteam',
    // meta: {
      // requireLogin: true
    // },
    component: () => import('@/views/Lvteam.vue'),
    children: [
      {
        path: 'price',
        name: 'Price',
        component: () => import('@/views/Price')
      }
    ]
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account')
  },
  {
    path: '/bindWx',
    name: 'BindWx',
    component: () => import('@/views/BindWx')
  },
  {
    path: '/customDetail',
    name: 'CustomDetail',
    component: () => import('@/views/CustomDetail')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
];

if(sessionStorage.getItem('userNavList')){
  console.log('routerJS存在session路由')
  let sessionRoutes = sessionStorage.getItem('userNavList');
  sessionRoutes = JSON.parse(sessionRoutes);
  sessionRoutes = routesFilter(sessionRoutes);
  routes = routes.concat(sessionRoutes);
};
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to,from,next)=>{
  if(sessionStorage.getItem('token')){
    if(to.path==='/login'){
      next('/home/homeMain')
    }else{
      next()
    }
  }else{
    if(to.meta&&to.meta.requireLogin){
      next('/login')
    }else{
      next()
    }
  }
})

export default router;