<template>
  <teleport to="body">
    <transition name="fade" @after-leave="afterLeave">
      <div class="dialogBg" v-if="isShow">
        <div class="dialogCon">
          <div class="dialogHeader">
            <div class="title" v-if="!isDesignTitle">
              {{ title }}
            </div>
            <slot name="title"></slot>
            <div
              v-if="!isDesignTitle"
              @click="myCloseDialog"
              class="iconfont iconguanbi"
            ></div>
          </div>
          <div class="dialogBody">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { defineComponent, watchEffect } from "vue";
export default defineComponent({
  name: "myDialog",
  emits: ["dialogAfterLeave"],
  props: {
    parentRef: {
      type: String,
      default: "",
    },
    isShow: {
      type: Boolean,
      require: true,
      default: false,
    },
    closeDialog: {
      type: Function,
      require: true,
      default: () => {},
    },
    title: {
      type: String,
      default: "消息",
    },
    isDesignTitle: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    watchEffect(() => {
      if (props.isShow) {
        document.getElementsByTagName("body")[0].style = "overflow:hidden";
      } else {
        document.getElementsByTagName("body")[0].style = "";
      }
    });
    const afterLeave = (el) => {
      emit("dialogAfterLeave", el);
    };
    const myCloseDialog = () => {
      if (props.parentRef) {
        props.closeDialog(props.parentRef);
      } else {
        props.closeDialog();
      }
    };
    return {
      afterLeave,
      myCloseDialog,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.dialogBg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.6);
  .dialogCon {
    min-width: 600px;
    // min-height:400px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    .dialogHeader {
      width: 100%;
      height: 60px;
      position: relative;
      border-bottom: 1px solid #eee;
      .title {
        width: 100%;
        line-height: 60px;
        font-size: 16px;
        color: #555;
        text-align: center;
      }
      .iconfont {
        font-size: 16px;
        color: #777;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        &:hover {
          cursor: pointer;
          color: $main-color;
        }
      }
    }
    .dialogBody{
      overflow-y: scroll;
      max-height: 70vh;
    }
  }
}
</style>
