import qs from 'qs';
import myserver from './myHttp.js';
// 使用auto token时候加
// header:{
//   'Authorization':'Basic bHZ0ZWFtOlNvb3EzVEtLRE84SmNwWXE=', 
// }
// 测试API
// 'Content-Type':'application/x-www-form-urlencoded'
// export const product = (params) => {
//   return myserver.myHttp({
//       url: '/api/product/page',
//       method: 'GET',
//       params,
//       headers:{
//         'Authorization':'ceshiheaderxinxi', 
        
//       }
//   })
// };
export const login = (params) => {
  return myserver.myHttp({
      url: '/oauth/token',
      method: 'POST',
      data:params,
      headers:{
        'Authorization':'Basic bHZ0ZWFtOlNvb3EzVEtLRE84SmNwWXE=', 
        'Content-Type':'application/x-www-form-urlencoded'
      }
  })
};
export const register = (params) => {
  return myserver.myHttp({
      url: '/openApi/register',
      method: 'POST',
      data:params
  })
};
export const sendVerifyCode = (params) => {
  return myserver.myHttp({
      url: '/openApi/sendVerifyCode',
      method: 'POST',
      data:params
  })
};
export const getTmpSubscribeQrCode = (params) => {
  return myserver.myHttp({
      url: '/wx/getTmpSubscribeQrCode',
      method: 'GET',
      params: params
  })
};
export const getTokenByTicket = (params) => {
  return myserver.myHttp({
      url: '/openApi/getTokenByTicket',
      method: 'GET',
      params: params
  })
};
export const quickCreate = (params) => {
  return myserver.myHttp({
      url: '/todo/quickCreate',
      method: 'POST',
      data: params
  })
};
export const create = (params) => {
  return myserver.myHttp({
      url: '/todo/create',
      method: 'POST',
      data: params
  })
};
export const today = (params) => {
  return myserver.myHttp({
      url: '/todo/today',
      method: 'GET',
      params
  })
};
export const weekView = (params) => {
  return myserver.myHttp({
      url: '/todo/weekView',
      method: 'GET',
      params
  })
};
export const monthView = (params) => {
  return myserver.myHttp({
      url: '/todo/monthView',
      method: 'GET',
      params
  })
};
export const detail = (params) => {
  return myserver.myHttp({
      url: '/todo/detail',
      method: 'GET',
      params
  })
};
export const remove = (params) => {
  return myserver.myHttp({
      url: '/todo/remove',
      method: 'POST',
      data:params
  })
};
export const update = (params) => {
  return myserver.myHttp({
      url: '/todo/update',
      method: 'POST',
      data:params
  })
};
export const check = (params) => {
  return myserver.myHttp({
      url: '/todo/check',
      method: 'POST',
      data:params
  })
};
export const getPrePayOrder = (params) => {
  return myserver.myHttp({
      url: '/wx/getPrePayOrder',
      method: 'GET',
      params
  })
};
// 查询字典
// vuex中action中调用getDataDictionary
export const getListByDictType = (params) => {
  return myserver.myHttp({
      url: '/sysDict/getListByDictType',
      method: 'GET',
      params
  })
};
export const forgetPwd = (params) => {
  return myserver.myHttp({
      url: '/openApi/forgetPwd',
      method: 'POST',
      data:params
  })
};
export const validateVerifyCode = (params) => {
  return myserver.myHttp({
      url: '/sysSmsTemplate/validateVerifyCode',
      method: 'POST',
      data:params
  })

};
export const getUserInfoAfterScan = (params) => {
  return myserver.myHttp({
    url: '/openApi/getUserInfoAfterScan',
    params
  })
}
export const userBindOpenId = (data) => {
  return myserver.myHttp({
    url: '/wx/userBindOpenId',
    method: 'POST',
    data
  })
}
export const getGreeting = (params) => {
  return myserver.myHttp({
    url: '/sysGreeting/get',
    params
  })
}
export const saveCaseBasicInfo = (data) => {
  return myserver.myHttp({
    url: '/tCase/saveCaseBasicInfo',
    method: 'POST',
    data,
    headers:{
      "NON-SELECT-TENANT-ID":""
    }
  })
}
export const customerNameSelect = (params) => {
  return myserver.myHttp({
    url: '/customer/customerNameSelect',
    method: 'get',
    params
  })
}
export const conflictOfInterestCheck = (params) => {
  return myserver.myHttp({
    url: '/tCaseStakeholders/conflictOfInterestCheck',
    method: 'get',
    params
  })
}
export const lawyerSelect = (params) => {
  return myserver.myHttp({
    url: '/sysTenantUser/lawyerSelect',
    method: 'get',
    params
  })
}
export const customerSelect = (params) => {
  return myserver.myHttp({
    url: '/customer/customerSelect',
    method: 'get',
    params
  })
}
export const getLanderUserSelect = (params) => {
  return myserver.myHttp({
    url: '/sysTenant/getLanderUserSelect',
    method: 'get',
    params
  })
}
export const casePage = (params,headerConfig) => {
  return myserver.myHttp({
    url: '/tCase/Page',
    method: 'get',
    params,
  },headerConfig)
}
export const getCaseInfo = (params) => {
  return myserver.myHttp({
    url: '/tCase/getCaseInfo/'+params.caseId,
    method: 'get',
    params
  })
}
export const saveCaseOtherInfo = (data) => {
  return myserver.myHttp({
    url: '/tCase/saveCaseOtherInfo',
    method: 'post',
    data
  })
}
export const getRelLawyerList = (params) => {
  return myserver.myHttp({
    url: '/tRelLawyer/getRelLawyerList',
    method: 'get',
    params
  })
}
export const addCatalogue = (data) => {
  return myserver.myHttp({
    url: '/tDossier/addCatalogue',
    method: 'POST',
    data
  })
}
export const dossierTree = (params) => {
  return myserver.myHttp({
    url: '/tDossier/dossierTree',
    method: 'get',
    params
  })
}
export const renameDossier = (data) => {
  return myserver.myHttp({
    url: '/tDossier/renameDossier',
    method: 'POST',
    data
  })
}
export const saveCaseStrategy = (data) => {
  return myserver.myHttp({
    url: '/tCaseStrategy/saveCaseStrategy',
    method: 'POST',
    data
  })
}
export const getCaseStrategyList = ({caseId}) => {
  return myserver.myHttp({
    url: '/tCaseStrategy/getCaseStrategyList/'+caseId,
    method: 'GET'
  })
}
export const deleteCaseStrategy = ({strategyId}) => {
  return myserver.myHttp({
    url: '/tCaseStrategy/deleteCaseStrategy/'+strategyId,
    method: 'GET'
  })
}
export const getCaseStrategyInfo = ({strategyId}) => {
  return myserver.myHttp({
    url: '/tCaseStrategy/getCaseStrategyInfo/'+strategyId,
    method: 'GET'
  })
}
export const saveCharge = (data) => {
  return myserver.myHttp({
    url: '/tCharge/saveCharge',
    method: 'POST',
    data
  })
}
export const getRealChargePage = (params) => {
  return myserver.myHttp({
    url: '/tCase/getRealChargePage',
    method: 'GET',
    params
  })
}
export const getChargeInfo = ({chargeId}) => {
  return myserver.myHttp({
    url: '/tCharge/getChargeInfo/'+chargeId,
    method: 'GET',
  })
}
export const updateCharge = (data) => {
  return myserver.myHttp({
    url: '/tCharge/updateCharge',
    method: 'POST',
    data
  })
}
export const saveApply = (data) => {
  return myserver.myHttp({
    url: '/tApply/save',
    method: 'POST',
    data
  })
}
export const tChargeDelete = (data) => {
  return myserver.myHttp({
    url: '/tCharge/delete',
    method: 'POST',
    data
  })
}
// 获取审批人列表
export const getReviewUserList = (params) => {
  return myserver.myHttp({
    url: "tLawOfficeReviewer/getByType",
    params
  },{
    headers: {
      "SELECT-TENANT-ID": "OFFICE",
    }
  });
};
// 文件上传
export const uploadFile = (data) => {
  return myserver.myHttp({
    url: '/tFile/uploadAttachment',
    method: 'POST',
    data
  })
}
export const addDossier = (data) => {
  return myserver.myHttp({
    url: '/tDossier/addDossier',
    method: 'POST',
    data
  })
}
export const delDossier = ({dossierId}) => {
  return myserver.myHttp({
    url: '/tDossier/delDossier/'+dossierId,
    method: 'PUT'
  })
}
export const moveDossier = (data) => {
  return myserver.myHttp({
    url: '/tDossier/moveDossier',
    method: 'POST',
    data
  })
}
export const filingDossier = (data) => {
  return myserver.myHttp({
    url: '/tDossier/filingDossier',
    method: 'POST',
    data
  })
}
export const getFillTree = (data) => {
  return myserver.myHttp({
    url: '/tDossier/getFillTree',
    method: 'GET',
    params:data
  })
}
export const getTree = (data) => {
  return myserver.myHttp({
    url: '/dDossierTemplate/getTree',
    method: 'GET',
    params:data
  })
}
export const generate = (data) => {
  return myserver.myHttp({
    url: '/tDossier/generate',
    method: 'post',
    data
  })
}
export const packageInfo = (data) => {
  return myserver.myHttp({
    url: '/tDossier/packageInfo',
    method: 'GET',
    params:data
  })
}
export const finish = (data) => {
  return myserver.myHttp({
    url: '/tCase/finish',
    method: 'POST',
    data
  })
}
export const deleteCase = (data) => {
  return myserver.myHttp({
    url: '/tCase/delete',
    method: 'POST',
    data
  })
}

