// 路由模板
const routeComponent = (component)=>{
  return () => import(`../views/${component}.vue`)
};
const routesFilter = (routesList)=>{
  routesList.forEach(item=>{
    if(item.component){
      item.component = routeComponent(item.component);
      if(item.children&&item.children.length){
        routesFilter(item.children)
      }
    }
  })
  return routesList
};
export{
  routeComponent,
  routesFilter
}