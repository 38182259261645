import myserver from './myHttp'
export const globalSearch = (params) => {
  return myserver.myHttp({
    url: '/todo/globalSearch',
    params
  })
}
export const searchToDo = (params) => {
  return myserver.myHttp({
    url: '/todo/search',
    params
  })
}
export const searchCustomer = (params) => {
  return myserver.myHttp({
    url: '/customer/search',
    params
  })
}
export const searchCase = (params) => {
  return myserver.myHttp({
    url: '/tCase/search',
    params
  })
}
export const searchProject = (params) => {
  return myserver.myHttp({
    url: '/tProject/search',
    params
  })
}
export const searchApply = (params) => {
  return myserver.myHttp({
    url: '/tApply/search',
    params
  })
}