import myserver from './myHttp'
export const getProductAll = (params) => {
  return myserver.myHttp({
    url: '/sysProduct/productAll',
    params
  })
}
export const getDate = (params) => {
  return myserver.myHttp({
    url: '/sysProduct/termOfValidity',
    params
  })
}
export const getPayOrder = (data) => {
  return myserver.myHttp({
    url: '/sysPayOrder/genProductPayOrder',
    method: 'POST',
    data
  })
}
export const getPrePayOrder = (params) => {
  return myserver.myHttp({
    url: '/wx/getPrePayOrder',
    params
  })
}
export const wxPayCB = (params) => {
  return myserver.myHttp({
    url: '/sysPayOrder/payOrderInfo',
    params
  })
}
