import _axios from '../utils/request2.js';
import { ElMessage } from 'element-plus';
import store from '../store';
function myserver(){
  if(myserver.myself){
    return myserver.myself
  };
  myserver.myself = this;
  this.serverMap = new Map();
};
myserver.myself = null;
myserver.prototype.resolveParams = function(config){
  let params = '';
  let configMethod = '';
  const httpType = ['POST','PUT','DELETE'];
  if(config&&config.data){
    params = JSON.stringify(config.data)
  }else if(config&&config.params){
    params = JSON.stringify(config.params)
  };
  if(config.method){
    let isGet = config.method.toUpperCase();
    if(isGet!=='GET'&&httpType.includes(isGet)){
      configMethod = isGet;
      return `${config.url}=>${configMethod}=>${params}`
    }
  }
  configMethod = '';
  return false
};
myserver.prototype.setHeaders = function(config,headerConfig){
  if(sessionStorage.getItem('token')){
    config.headers = {
      "Authorization":"Bearer "+sessionStorage.getItem('token')
    }
  };
  if(config.headers&&config.headers['NON-SELECT-TENANT-ID']){
    for(let key in config.headers){
      if(key === 'NON-SELECT-TENANT-ID'){
        config.headers["NON-SELECT-TENANT-ID"] = store.state.getCurrentbeLoingsId
      };
    };
  };
  if(headerConfig&&headerConfig.headers){
    Object.keys(headerConfig.headers).forEach(item=>{
      config.headers[item] = headerConfig.headers[item]
    })
  };
  return config;
}
myserver.prototype.myHttp = function(origConfig,headerConfig={}){
  let config = this.setHeaders(origConfig,headerConfig);
  const mapKey = this.resolveParams(config);
  if(mapKey){
    if( this.serverMap.has(mapKey) ){
      ElMessage({
        showClose: true,
        message: '请勿重复提交',
        type: 'warning'
      });
      return
    }else{
      this.serverMap.set(mapKey,``);
    }
  };
  return _axios(config).then(res=>{
    return res
  },err=>{
    if(err.response){
      return err.response.data
    }else{
      console.log('err:',err)
      // throw new Error(err);
      return err
    }
  }).catch(err=>{
    ElMessage({
      showClose: true,
      message: '服务器遇到点问题',
      type: 'error'
    });
  }).finally(()=>{
    if(this.serverMap.has(mapKey)){
      this.serverMap.delete(mapKey)
    }
  })
}
export default new myserver